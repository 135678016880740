import {Button, Image, Tooltip, OverlayTrigger} from 'react-bootstrap'
import {abilityAssets} from '../../utils/assets'
import {useState} from 'react'
import CastBar from './CastBar'

const Abilities = ({character, socket, target, isActionsBlocked, handleBlockActions, attackCd}) => {
  const [isAttackBlocked, setIsAttackBlocked] = useState(false)

  const onAttackClick = () => {
    setIsAttackBlocked(true)

    setTimeout(() => {
      setIsAttackBlocked(false)
    }, attackCd)

    socket.emit('spell', {
      target,
      spellId: 'attack',
    })
  }

  const onActionClick = (spellId) => {
    handleBlockActions()

    socket.emit('spell', {
      target,
      spellId,
    })
  }

  return (
    <div className="d-flex abilities align-items-center justify-content-center px-2" style={styles.abilities}>
      {character.stats.spellCast && character.stats.spellCast.name &&
        <CastBar castTime={character.stats.spellCast.time} spellName={character.stats.spellCast.name}/>}

      {Object.keys(character.spells).filter((s) => character.level >= character.spells[s].level).map((sp) => {
        const spell = character.spells[sp]
        return <OverlayTrigger
          key={sp}
          placement="top"
          delay={{show: 1000, hide: 1000}}
          overlay={
            <Tooltip className="custom-tooltip">
              <div>
                <strong>{spell.name}</strong><span className="float-end">{spell.castTime > 0 ? `${Math.floor(spell.castTime / 1000)} sec cast` : `instant`}</span><br/>
                {spell.manaCost > 0 && <small>{Math.floor(spell.manaCost / 100)} mana<br/></small>}
                {spell.description}
              </div>
            </Tooltip>
          }
        >
          <Button
            className={'ability ms-2 p-0'}
            variant="light"
            type="button"
            size={(sp === 'attack' || sp === 'shoot wand') ? 'lg' : 'sm'}
            onClick={sp === 'attack' ? (() => onAttackClick()) : (() => onActionClick(sp))}
            disabled={sp === 'attack' ? isAttackBlocked : isActionsBlocked}
            style={(sp === 'attack' || sp === 'shoot wand') ? styles.attack : {}}
          >
            <Image src={abilityAssets[sp]}/>
            {sp === 'attack' && <div
              className={'cooldown-overlay' + (isAttackBlocked ? ' start-animation' : '')}
              style={isAttackBlocked ? {animation: `cooldown-animation ${attackCd}ms linear forwards`} : {}}
            ></div>}
          </Button>
        </OverlayTrigger>
      })}
    </div>
  )
}

const styles = {
  abilities: {
    position: 'absolute',
    bottom: 60,
    left: '50%',
    transform: 'translateX(-50%)',
    height: 80,
    fontSize: '9px',
    textAlign: 'center',
    borderRadius: 10,
    opacity: 0.85,
    background: '#919995',
    zIndex: 1,
  },
  attack: {
    borderRadius: 8,
    width: 64,
    height: 64,
    position: 'relative',
  },
}

export default Abilities
